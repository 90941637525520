// strings.js

const strings = {
    STRIPE_DEV_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/test_eVa157eVDbNTdSU159", // ma
    STRIPE_PROD_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/aEU9Bt0t05Fkc36bJN", // ma

    STRIPE_SUBSCRIPTION_MANAGEMENT_URL: "https://billing.stripe.com/p/login/28ocP200L31J9gs7ss",
    SUBSCRIPTION_STATUS_API_URL: "https://funquiz.app/modular2/subscription-status",
    
    GENERIC_PAGE_TITLE: "Money Art Gen AI 🎨",
    HOME_PAGE_TITLE: "Home - Money Art Gen AI 🎨",
    DASHBOARD_PAGE_TITLE: "Home - Money Art Gen AI 🎨",
    WELCOME_MESSAGE_H1_TITLE: "Welcome to Money Art Gen AI 🎨",
    FX_DESCRIPTION_MESSAGE: "This f(x) can be used to generate money style art, enter a prompt and then press the 'Submit Input' button below to get your result!",
    
    MAIN_API_NAME: "money_art_gen_ai_1",
    MAIN_API_URL: "https://funquiz.app/modular2/money-art-gen-ai-1",

    PROD_CLERK_PUB_KEY: "pk_live_Y2xlcmsueW9sbGVnLmNvbSQ",
    DEV_CLERK_PUB_KEY: "pk_test_aGFybWxlc3MtYmVlLTY5LmNsZXJrLmFjY291bnRzLmRldiQ",

    // ... add more strings as needed
};



export default strings;